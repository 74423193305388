:root{
  // colors
  --primaria: #E87600;
  --contrast: #008f72;
  --white: #ffffff;
  --azul: #21374C;
  --texto: #4f4f4f;
  --azul-opacidade: rgba(33, 55, 76, .6);
  --border-color: #EEEEEE;
  --cinza: #BDBDBD;
  --cinza-forte: #999999;
  --cinza-fraco: rgba(196, 196, 196, 0.3);
  --link: #E87600;
  --botao-cor: #8794A0;
  --botao-azul: #E87600;
  --background-cinza: #F7F7F7;


  //------------
  // HEADER
  //------------
  --header-background: #191919;
  --header-text-primary: #E87600;
  --header-text-default: #d0d0d0;
  --header-text-title: #999999;
  --header-border: #27272a;
  --header-search-background: #000000;
  --header-logo: #ffffff;

  //------------
  //BODY
  //------------
  --body-background: linear-gradient(to bottom, #191919 320px, #fff 320px);

  //------------
  // TEXT
  //------------
  --text-primary: #E87600;
  --text-secundary: #878E88;
  --text-default: #333333;
  --text-gray: #8692A6;
  --text-dark: #333333;

  //------------
  // FOOTER
  //------------
  --footer-logo: #ffffff;
  --footer-background: #191919;
  --footer-text: #d0d0d0;
  --footer-link: #E87600;

}